.upload-area {
  width: 100%;
  background: #ebefff;
  border: 2px dashed #051d7a;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 32px;
  padding: 1rem;
outline: none;
  img {
    height: 2rem;

    @media (min-width: 768px) {
      height: 3rem;
    }
  }

  & > .text {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    margin-top: 4px;

    color: #575757;
  }
}
