.file-box {
  margin-bottom: 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 4px;
  min-height: 160px;

  & > .title {
    font-style: normal;
    font-weight: bold;
    color: #666666;
    margin-bottom: 8px;
  }

  & > .box {
    box-shadow: 0px 0px 6px rgba(1, 1, 1, 0.24);
    overflow: auto;
    border-radius: 6px;
    width: 100%;
    flex-grow: 1;
    padding: 16px 12px;

    background: #f1f1f1;
    &.my-files {
      background: #f9f9f9;
    }

    &.no-files {
      display: flex;
      align-items: center;
    }

    .no-files-text {
      font-family: Nunito;
      font-style: normal;
      font-weight: normal;
      text-align: center;
      color: #666666;

      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.file-content {
  display: flex;
  text-decoration: none;
  &:not(:first-child) {
    margin-top: 24px;
  }

  .file-icon {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      margin-right: 8px;
      width: 25px;
    }
  }
  .file-content-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;

    .file-name {
      color: #3f4049;
      // margin-bottom: 4px;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-size {
      color: rgba(63, 64, 73, 0.8);
      // margin-bottom: 4px;
    }
  }
  .file-tool {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      margin-left: 16px;
      width: 25px;
    }
  }
}
