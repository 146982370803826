.room {
  background: #051d7a;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  @media (min-width: 768px) {
    padding-bottom: 32px;
    padding-right: 24px;
    padding-left: 24px;
  }

  & > .logout {
    width: 100%;
    font-style: normal;
    font-weight: normal;
    color: #f9f9f9;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    .btn-logout {
      cursor: pointer;
      display: flex;
      img {
        margin-left: 8px;
      }
    }
  }

  & > .header {
    width: 100%;
    position: relative;
    text-align: center;
    margin-bottom: 24px;
    .logo {
      height: 24px;

      @media (min-width: 768px) {
        height: 40px;
      }
    }
    .room-name {
      position: absolute;
      right: 16px;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      text-align: right;
      color: #f9f9f9;
      font-weight: lighter;
    }
  }
  & > .text {
    text-align: center;
    color: #f9f9f9;
    margin-bottom: 32px;
    font-weight: lighter;
  }
  & > .box {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
    padding: 24px 16px 24px 16px;
    background: #f9f9f9;
    width: 100%;
    border-radius: 20px 20px 0 0;

    @media (min-width: 768px) {
      border-radius: 20px;
      width: 80%;
      padding: 32px;
      overflow: unset;
    }

    & > .title {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      text-align: center;
      margin-bottom: 24px;
      color: #1c1d29;
    }
  }

  .filex-boxes {
    flex: 1;
    display: flex;
    flex-direction: column;
    .space {
      display: none;
    }
    @media (min-width: 768px) {
      & {
        flex-direction: row;
        .space {
          width: 32px;
          height: 100%;
          display: block;
        }
      }
    }
  }
  .powered-by{
    text-align: center;
    width: 100%;
    color: #666666;
    .zeero{
      color: #051D7A;
      text-decoration: none;

    }
  }
}
