#home-page {
  overflow-x: hidden;
  flex-direction: column;

  .landing-container {
    position: relative;
    width: 100vw;
    height: 90vh;
    min-height: 750px;
    flex-direction: column;
    align-items: center;
    flex-direction: column;
    align-content: center;
    text-align: center;
    justify-content: center;
    padding: 8px 16px;

    .icon {
      height: 210px;
      margin-bottom: 32px;
    }

    .title {
      margin-bottom: 1.5rem;
      width: 180px;
    }
    .subtitle {
      font-size: 1.9rem;
      font-weight: bold;
      margin-bottom: 24px;
    }
    .instructions {
      color: var(--gray-color);
      font-size: 18px;
      //   padding: 2px 5px;
      //   margin: 16px 0;
    }
    .arrow {
      position: absolute;
      bottom: 20px;
      margin: 0 auto;
      width: 40px;
      animation: float 6s ease-in-out infinite;
    }
    .powered-by{
        display: none;
    }
    .subtitle {
      font-size: 1.9rem;
      font-weight: bold;
      margin-bottom: 24px;
    }
    .instructions {
      color: var(--gray-color);
      font-size: 18px;
      padding: 2px 5px;
    }
  }
  .qr-container {
    width: 100vw;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 16px;
    color: var(--light-color);
    padding-top: 40px;

    .qr-code {
      padding: 20px;
      width: 220px;
      height: 220px;
      background-size: cover;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: cover;
    }

    .instructions {
      color: var(--light-color);
      font-size: 16px;
      font-weight: 200;
      margin: 1rem 0;

      p {
        margin: 2rem 0;
      }
    }
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@media (min-width: 768px) {
  #home-page {
    display: flex;
    flex-direction: row;
    .landing-container {
      width: 100vw;
      height: 100vh;
      flex-direction: column;
      text-align: center;
      align-content: center;
      justify-content: center;
      padding-left: 56px;
      padding-right: 56px;

      .icon {
        margin-bottom: 40px;
      }

      .title {
        color: var(--primary-color);
        font-size: 5rem;
        margin-bottom: 2rem;
      }
      .subtitle {
        font-size: 2.4rem;
        font-weight: bold;
      }
      .instructions {
        color: var(--gray-color);
        font-size: 18px;
        padding: 2px 5px;
      }

      .arrow {
        display: none;
      }
      
    .powered-by {
        position: absolute;
        bottom: 24px;
        margin: 0 auto;
        /* width: 40px; */
        display: block;
        a{
            text-decoration: none;
        }
      }
    }
    .qr-container {
      width: 80vw;
      height: 100vh;
      flex-direction: column;
      text-align: center;
      align-items: center;
      padding: 50px;
      color: var(--light-color);

      .instructions {
        color: var(--light-color);
        font-size: 16px;
        font-weight: 200;
        margin: 1rem 0;

        p {
          margin: 2rem 0;
        }
      }
    }
  }
}
