* {
  font-family: "Nunito";
  outline: none;
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  background-color: #3f4049;
}

//fonts

@for $i from 1 through 5 {
  .font-#{$i} {
    font-size: 10 + $i * 2px;
  }
}

@for $i from 1 through 5 {
  @media (min-width: 768px) {
    .font-#{$i} {
      font-size: 14 + $i * 2px;
    }
  }
}

.no-scroll {
  overflow: hidden;
}

.btn {
  height: 40px;
  border: none;
  padding-left: 22px;
  padding-right: 22px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  color: var(--light-color);

  @media (min-width: 768px) {
    border: none;
    border-radius: 3px;
    font-size: 18px;
  }

  &.btn-primary {
    background: var(--primary-color);
  }
  &.btn-secondary {
    background: var(--secondary-color);
  }

  &.btn-default {
    border: 1px solid #b3b3b3;
    color: var(--dark-color);
    background: transparent;
  }

  &.lg {
    height: 50px;

    @media (min-width: 768px) {
      height: 56px;
    }
  }
}
.btn-footer {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  .btn {
    margin-left: 16px;
  }
}
