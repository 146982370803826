
.linear-progress-material {
  position: relative;
  width: 100%;
  margin: 0 auto;
  // margin-top: 2rem;
  overflow: hidden;
  -webkit-animation: start 0.3s ease-in;
  border: 1px solid #dfdfdf;
  animation: start 0.3s ease-in;

  background: #dfdfdf;
  height: 5px;
  border-radius: 30px;
}
.linear-progress-material .bar {
  border-radius: 30px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: all 0.2s linear;
  transition: all  0.2s linear, -webkit-transform 0.2s linear;
  // height: 4px;
}