.modal {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .overlay {
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.6);
    position: absolute;
    z-index: 1;
  }

  .card {
    max-width: 400px;
    width: 90%;

    background: #FEFEFE;
    box-shadow: 0px 0px 6px rgba(1, 1, 1, 0.24);
    border-radius: 6px;
    padding: 24px;
    z-index: 2;
  }
}
