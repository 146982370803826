//Colors variables
:root{
    --primary-color: #051D7A;
    --secondary-color: #F0B23E;
    --secondary-color-hover: #f0b23ee0;
    --dark-color: #1C1D29;
    --light-color: #F9F9F9;
    --gray-color: #666666;
}

//Display Configs
.db{
    display: block;
}
.df{
    display: flex;
}

//Background Colors
.bg-blue{
    background-color: var(--primary-color);
}
.bg-yellow{
    background-color: var(--secondary-color);
}
.bg-white{
    background-color: var(--light-color);
}

//Text Colors
.t-blue{
    color: var(--primary-color);
}
.t-yellow{
    color: var(--secondary-color);
}
.t-dark{
    color: var(--dark-color);
}
.t-light{
    color: var(--light-color);
}
.t-gray{
    color: var(--gray-color);
}
